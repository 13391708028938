.tiptap {
  min-height: 400px;
  padding: 5px;
}

.tiptap:focus {
  outline: none;
}

.tiptap h1 {
  font-size: 54px;
}

.tiptap h2 {
  font-size: 48px;
}

.tiptap h3 {
  font-size: 32px;
}

.tiptap blockquote {
  border-left: 8px solid var(--chakra-colors-blue-500);
  padding-left: 10px;
}
