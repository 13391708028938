.entry-grid {
  background-color: transparent;
  border-color: var(--chakra-colors-gray-100);
}

.entry-grid .rdg-header-row {
  background-color: var(--chakra-colors-gray-300);
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  color: var(--chakra-colors-gray-600)
}

.entry-grid .rdg-row {
  color: var(--chakra-colors-black);
  background-color: transparent;
  cursor: pointer;
}

.entry-grid .rdg-row:nth-child(even) {
  background-color: var(--chakra-colors-gray-100);
}

.entry-grid .rdg-cell {
  border: 1px solid var(--chakra-colors-gray-200);
}

.entry-grid input {
  background-color: transparent;
  color: var(--chakra-colors-black);
  padding-left: 6px
}

.entry-grid--error {
  border: 1px solid var(--chakra-colors-red-500)
}

.entry-grid__delete-header {
  display: none;
}

.entry-grid__delete-cell {
  background-color: transparent;
  border: none !important;
  max-width: 32px;
  padding: 0px;
}