body {
  color: #555555;
  font-family: 'Roboto', sans-serif;
  background-image: none;
}

/* Globally style react-datepicker to match chakra inputs */
.react-datepicker__input-container input {
  width: 100%;
  outline: 2px solid transparent;
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background-color: var(--chakra-colors-white);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
}

.react-datepicker__input-container input:focus {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

/* .chakra-popover__body {
  background-color: var(--chakra-colors-gray-800);
  font-size: var(--chakra-fontSizes-sm);
  color: var(--chakra-colors-white);
} */
