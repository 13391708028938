.release-notes {
  text-align: left;
}

.release-notes h1 {
  font-size: var(--chakra-fontSizes-5xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin: 10px 0px
}

.release-notes h2 {
  font-size: var(--chakra-fontSizes-4xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin: 10px 0px
}

.release-notes h3 {
  font-size: var(--chakra-fontSizes-3xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin: 10px 0px
}

.release-notes h4 {
  font-size: var(--chakra-fontSizes-2xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin: 10px 0px
}

.release-notes h5 {
  font-size: var(--chakra-fontSizes-xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin: 10px 0px
}

ul {
  margin-left: 20px
}