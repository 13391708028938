.backdrop {
  background: linear-gradient(20deg, var(--chakra-colors-blue-50), var(--chakra-colors-blue-100));
}

.fish {
  animation: slide 5s infinite;
  opacity: 0%;
  position: absolute;
  top: calc(40%);
}

.fish-0 {
  animation-delay: 500ms;
  top: calc(30%);
}

.fish-2 {
  animation-delay: 200ms;
  top: calc(60%);
}

@keyframes slide {
  from {
    transform: translateX(0);
    opacity: 0%;
  }

  50% {
    opacity: 100%;
  }

  80% {
    opacity: 0%;
  }

  to {
    transform: translateX(95%);
    opacity: 0%;
  }
}