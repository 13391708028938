.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 20%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

svg .rect {
  stroke: steelblue;
  stroke-width: 1px;
}

div.controls-overlay {
    position: absolute;
    bottom: 40px;
    right: 0;
    margin: 10px;
}

div.controls-overlay div {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  margin: 3px;
  text-align: center;
}

div#player-controls {
  margin-left: 4%;
}

.slider-main{
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  transition: width 0.1s ease 0s;
  cursor: pointer;
  margin-top: 3%;
}

.slider-marker{
  position: absolute;
  width: 16px;
  height: 16px;
  background: rgb(114, 214, 135);
  border-radius: 100%;
  transform: scale(1);
  transition: transform 0.2s ease 0s;
  top: 0px;
  margin-top: -4px;
  margin-left: -8px;

}