.map-wrapper {
    height: 100vh;
  }

.bottom-controls{
  position: absolute;
  bottom: 4rem;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.map-popup {
  animation: fadeInAnimation ease .25s;
}

.menu-nav{
  position: absolute;
  top: 4rem;
  left: 1rem;
}