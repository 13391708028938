.reportsVisibleLabel input[type="radio"] {
  display: none
}

.reportsVisibleLabel {
  cursor: pointer;
  display: block;
  padding: 1em;
}

.reportsVisibleChecked {
  background-color: var(--chakra-colors-gray-100);
}